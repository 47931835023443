<template>
    <div>
        <div  class="profile-header">
            <div class="general-info">
                <div  class="general-info-t">
                    <div  class="general-info-avatar">
                        <img  :src="userInfo.picUrl" alt="用户头像" style="width:100%;border-radius:50%;">
                        <div  class="avatar-hover">
                            <el-upload
                                action="/"
                                :http-request="uploadFile"
                                :show-file-list="false">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAFC0lEQVR4Xu2ba8ilUxTHf39J5H6NQiQSii80bjENNeEDmtw1Jb64jhqF1AwJZcow+EJqmnJLzIeZmkJoxMQXComkjCL3cckk+WvpOdM++33OOc9znufMeY9zVp0v77P22mv991prr7X3fsWUk6bcfmYAzDxgyhGYhcCUO8DOTYK2d6kCuKR/qvC1wTPSELB9CHAVcA5wGLBrRaX/Br4B3gKelfRdxXG12UYGgO3TgQeAvWtr1T3gN+BuSe82lFM6fCQA2D4eeBrYvSWltwPXS/q0JXk7xLQOgO3dwm2Bo1pW9kvgakl/tSl3FAAsA67JlHwKeB4Id65CETZXADdkzOskPVpFQFWeVgGwfXLh+qncjZJWVFUo5bN9H3BB8jcXofDhMPLKxrQGgO2I9+eAI5KJvgcuk1R15bt0tB2e8CJwcPJhK3ClpMgLjakyAIWBC4ATC4X2zGaPLS++pXSrpHeaaGn7TCB3+4+BfGv8AwjA49uWqgANBMD2PsB1wJKaWX29pPubGN8Za/se4OIassI7XgKekfRrv3F9AShi+mHggBqTB+u3wOWSYlUak+3wtheAQ2sK+wm4Q1LPnNETANvh7qtrVG8d3QL9myV9UFPZvuy2TwEer+mFITOqymWStlROgrYjka0D9soG/Qi8CnwBlK1uTPbRqErXorQ+qceihJccA5wPHJjp/TtwraRIoF1U6gG2HwHOzng3AKskhbB5S7Zj0ZYDF2VKbpZ0+0AAbEcmX5sxRjJ5ct5aXaKY7RuL5J1+XSopdokdNMcDbN8W7pLwfF64T7j3xJDt6DwjjI9NlJ5TSZYBEHX8ccmguyRF3E8c2Y588GCi+GeSoj3v6wGvA/smPIsl/dCG9cWqnAvE74SkwosC5hPgzfhJasXbbB8EbEp03yZpUU8AbIdHvJ8Zu6ANhWwvBCK8Dh8A5tdR+Ul6oynoBeD59neqpOgp/qOuECiOrN7LJj6tyRFVIfOWLK9UsS3id00Lc/e1Z2cAkCfVKsZ3eBq1v1UWdKQAFG4fpXROUVOsB+KQI+jootbP9+74FqXsUOEwVgCK+IuGJI35n4EVvTpE22cA9wL7J4hFTlgyTB4aNwDnAQ9lSz+wPS5AeCwbd6ek1+rETvCOG4BohRcnSm+QtLKKEbaDLw2HTZKiJa5F4wbgZeDIROM41a3UIRadX5wqd+grSZfWsn4eeMBmYI9E6UWStlUxwnYUYlGQdehPSXlzNlDUuD1g6gGY+hCY+iQ49dtg9OPTWwgVhUh0gNNZCnf2qJITpoHbV8Iw2c1QUo5ObzuceMI4DkTiSc54zwNSfx/Dkdj8AqBO8LfBO9ZSuA0DmsqoDUCRtN7O7t8WDnu/39SApuOL9wXpadJ2SWelcsvuBV7JHjlUbmObKtz2+JK2equkSwYBkNfwQz9xaduguvJsx/Hahcm4OQcrZR5QVsPH9XKExsSQ7XD1uN5Pac7RWhkAZTX8L8DKSQGhMD6O1fZLrC89XO11PR5XV6tKlnsjEDmi1/uAcXpI531AxHjq9h2dlkuKq7cu6vdCJMrXpeO0qMW510paUyavHwBRRd30PwAh3jo80euKrcorsQiHeP056FKzxQVrRVTE/Ooyt++7DZZNndXwnXeCbT2EbsVaIB5ndd4JVr5mH+gBvbSr+s8PbVk3SM6wt8hDAzBIoUn5PgNgUlZqVHrOPGBUyE6K3JkHTMpKjUrPfwGerYtf3LH0qAAAAABJRU5ErkJggg==" alt="">
                            </el-upload>
                        </div> 
                    </div>
                    <div>
                        <div  class="person-name">
                            {{userName}} 
                             <el-tooltip
                                class="user-age"
                                effect='light'
                                :content="`经龄${age}`"
                                placement="right">
                                <i class="iconfont ec-icon-age"></i>
                            </el-tooltip>
                            
                        </div>
                        
                        <!-- <div  style="margin-top: 10px;background-color:rgb(235,205,255);color:rgb(98,43,208);border-radius:10px;text-align:center;width: 90px;">
                            <span >经龄{{age}}</span>
                        </div>  -->
                        <div v-if='roleInfo'>
                            <span class="user-vip" :style="{'background-color':roleInfo?.bgColor,'border-color':roleInfo?.color}">
                                <span>
                                    <i class="iconfont ec-icon-vip" :style="{'color':roleInfo?.color}"></i>
                                </span>
                                {{roleInfo?.name}}
                            </span>
                        </div>
                    </div>                            
                   
                </div> 
                <div class="general-info-b">
                    <el-button size="small"  round @click.prevent="handleSelect('/people','people')">个人主页<i  class="iconfont ec-icon-arrow-right-bold"></i></el-button>
                   
                </div>
            </div> 
        </div>
        <div class="base-info">
            <span class="anchor_txt">基本信息</span>
            <el-form :model="userInfo" label-width="120px" style="width:500px;">
                <el-form-item label="账号">
                    <el-input v-model="userInfo.tel" disabled  />
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input v-model="userInfo.userName" />
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="userInfo.sex">
                        <el-radio :label="1" >男</el-radio>
                        <el-radio :label="2" >女</el-radio>
                        <el-radio :label="3" >未知</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="出生日期">
                    <el-date-picker
                        v-model="userInfo.birthday"
                        type="date"
                        placeholder="出生日期"
                        format='YYYY-MM-DD'
                    />
                </el-form-item>
                 <el-form-item label="邮箱">
                    <el-input v-model="userInfo.email" />
                </el-form-item>
            </el-form>
            <span class="anchor_txt">公司信息</span>
            <el-form :model="userInfo" label-width="120px" style="width:500px;">
                <el-form-item label="公司名称">
                    <el-input v-model="userInfo.company" />
                </el-form-item>
                <el-form-item label="所在城市">
                    <el-input v-model="userInfo.companyCity" />
                </el-form-item>
                <el-form-item label="所在行业">
                    <el-input v-model="userInfo.companyIndustry" />
                </el-form-item>
                <el-form-item label="职务">
                    <el-input v-model="userInfo.job" />
                </el-form-item>
            </el-form>
            <el-button type="primary" style="margin-left:120px;width:380px;" @click="saveUserInfoFun">保存</el-button>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import {parseTime,roleColors,hexToRGBA} from '@/utils'
import {getUserInfo,getRoleList,changeUser,uploadPic,setUserPicUrl,addUserLog,getUserRightFun} from '@/http/basicsApi'
import { useRoute, useRouter } from 'vue-router'
export default {
    setup() {
        const route=useRoute();
        const router=useRouter();
        const currentUser=JSON.parse( localStorage.getItem('currentUser'));//当前用户信息
        let userName=ref(currentUser.userName);
        let age=ref('')//用户注册时长
        let roleInfo=ref()//用户角色
        //获取用户信息
        let userInfo=ref({userId:0,userName:'',tel:'',email:'',sex:'',birthday:'',company:'',companyCity:'',companyIndustry:'', job:'',});//用户信息
        const getUserInfoFun=async()=>{
            //console.log('用户信息');
            if(currentUser){
                //console.log(currentUser);
                let result= await getUserInfo(currentUser.userId)
                console.log(result);
                if(result.code==20000){
                    getUserRoleFun(result.data.roleIds);
                    userInfo.value=result.data;
                    let num=new Date().getTime()-new Date(userInfo.value.createDate).getTime();
                    
                    let count=parseInt(num/(1000*60*60*24))
                    if(count>=360){
                        age.value=parseInt(count/360)+'年'
                    }
                    else if(count>=30){
                        age.value=parseInt(count/30)+'月'
                    }
                    else{
                        age.value=count+'天';
                    }
                }
            }
        }
        const getUserRoleFun=async(roleIds)=>{
            let params={
                pageSize:1,
                ids:roleIds
            }
            let result= await getRoleList(params)
            console.log(result);
            if(result.code==20000 && result.data && result.data.totalCount>0){
                
                let role=result.data.dataList.find(t=>t.name.indexOf('普通')>=0);
                // let role1=result.data.dataList.find(t=>t.name.indexOf('银')>=0);
                // let role2=result.data.dataList.find(t=>t.name.indexOf('金')>=0);
                let role3=result.data.dataList.find(t=>t.name.indexOf('VIP')>=0);
                //显示最大权限的角色
                if(role3){
                    roleInfo.value={name:role3.name,color:roleColors[3],bgColor:hexToRGBA(roleColors[3],0.2)}
                }
                // else if(role2){
                //     roleInfo.value={name:role2.name,color:roleColors[2],bgColor:hexToRGBA(roleColors[2],0.2)}
                // }
                // else if(role1){
                //     roleInfo.value={name:role1.name,color:roleColors[1],bgColor:hexToRGBA(roleColors[1],0.2)}
                // }
                else{
                    roleInfo.value={name:role.name,color:roleColors[0],bgColor:hexToRGBA(roleColors[0],0.2)}
                }
            }
        }
        //保存用户信息
        const saveUserInfoFun=async()=>{
            userInfo.value.birthday=parseTime(userInfo.value.birthday,'{y}-{m}-{d}')
            let result= await changeUser(userInfo.value)
            //console.log(result);
            if(result.code==20000){
                userName.value=userInfo.value.userName;
                currentUser.userName=userInfo.value.userName;
                localStorage.setItem('currentUser',JSON.stringify(currentUser))
                ElMessage.success({
                    message: result.message, 
                }); 
            }
            else{
                ElMessage.error({
                    message: result.message, 
                }); 
            }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'保存',JSON.stringify(userInfo.value));
        }
        const uploadFile=(ops)=> {
            const _file = ops.file
            //console.log(_file);
            if (_file.type !== 'image/jpeg' && _file.type!=='image/png') {
                ElMessage.error('请上传jpg、png格式的图片!')
                return false
            } else if (_file.size / 1024 / 1024 > 1) {
                ElMessage.error('图片的大小不大于1MB!')
                return false
            }
            const formData = new FormData()
            formData.append('file',_file)
            // 调用接口 - 这里就可以使用全局统一axios处理之后的接口
            uploadPic(formData,9).then((res) => {
                //console.log(res)
                //console.log(res.message || '文件导入成功，稍后查询处理结果')
                 setUserPicUrl({userId:userInfo.value.userId,picUrl:res.data}).then(picRes=>{
                    if(picRes.code==20000){
                        userInfo.value.picUrl=res.data;
                        currentUser.picUrl=res.data;
                        localStorage.setItem('currentUser',JSON.stringify(currentUser))
                    }
                })
            }).catch((err) => {
                throw err
            })
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'修改头像',JSON.stringify({userId:currentUser.userId}));
        }
        //功能菜单的选择事件
        const handleSelect=async (path,name)=>{// eslint-disable-line no-unused-vars
            if(await getUserRightFun(name,0)){
                router.push(path)
            }
        }
        
        hexToRGBA('#ff0011',0.5);
        //加载用户信息
        getUserInfoFun();
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载',JSON.stringify({userId:currentUser.userId}));
        return {
            userName,
            age,
            userInfo,
            roleInfo,
            saveUserInfoFun,
            uploadFile,
            handleSelect
        }
    }
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.general-info {
        margin-bottom: 10px;
        background: #fff;
        
    .general-info-t {
        padding: 24px 0 40px 24px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-shadow: 0 1px 8px 0 rgba(0,0,0,.04);
        box-shadow: 0 1px 8px 0 rgba(0,0,0,.04);
        .general-info-avatar {
            -ms-flex-negative: 0;
            flex-shrink: 0;
            position: relative;
            width: 96px;
            height: 96px;
            margin-right: 16px;
            -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.06);
            box-shadow: 0 0 10px 2px rgba(0,0,0,.06);
            border: 2px solid #fff;
            border-radius: 50%;
            cursor: pointer;
            &:hover .avatar-hover{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
            }
            .avatar-hover {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.5);
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                border-radius: 50%;
            }
        }
        .person-name {
            color: #222226;
            font-weight: 500;
            line-height: 24px;
            font-size: 20px;
            margin-right: 16px;
        }
    }
    .general-info-b{
        height: 48px;
        background: #fdfdfd;
        padding-left: 25px;
        font-size: 14px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        button{
            padding: 5px 15px;
            background-color: #f2f2f2;
        }
    }
}
.base-info {
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
}
.user-age{
    color: $themeColor;
}
.user-vip{
    // background-color: rgba(245, 245, 245, 0.2);
    border-radius: 20px;
    font-size: 16px;
    display: inline-block;
    padding-right: 10px;
    margin-top: 20px;
    padding-left: 30px;
    position: relative;
    padding-bottom: 1px;
    border:1px solid transparent;
    border-left: 0;
    span{
        position: absolute;
        height: 23px;
        width: 23px;
        display: inline-block;
        background-color: #fff;
        border-radius: 10px;
        left: 0;
        i{
            font-size: 34px;
            position: absolute;
            left: -4px;
            bottom: -1px;
            color: rgba(245, 245, 245,1);
        }
    }
}
</style>